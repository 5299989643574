import { queryOptions } from '@tanstack/react-query';
import axios from 'axios';

export const LEADERBOARD_ENTRIES_LIMIT = 8;

export interface LeaderboardEntry {
  name: string;
  stepsThisMonth: number;
  age: string;
  breed?: string;
  visitCount: number;
  photoUrl?: string;
}

export interface LeaderboardData {
  name: string;
  leaderboard: LeaderboardEntry[];
}

export const dogParkLeaderboardOptions = (id: string, autoRefresh: boolean = true) =>
  queryOptions({
    queryKey: ['dogParkId', { id }],
    queryFn: () => getDogParkLeaderboardData(id),
    refetchInterval: autoRefresh ? 300_000 : false, // 5 minute refresh interval
  });

async function getDogParkLeaderboardData(id: string) {
  const webApiBaseUrl = import.meta.env.VITE_WEB_REST_API ?? 'http://localhost:8080/api';
  const response = await axios.get<LeaderboardData>(
    `${webApiBaseUrl}/dog-parks/leaderboard/${id}?limit=${LEADERBOARD_ENTRIES_LIMIT}`,
  );
  return { ...response.data };
}

/** Intended for use with testing. TODO: move to storybook. */
export const SAMPLE_DATA: LeaderboardData = {
  name: 'Bar K',
  leaderboard: [
    {
      name: 'Garth Vader',
      stepsThisMonth: 293338,
      age: '7yrs 3mos',
      breed: 'Treeing Walker Coonhound',
      visitCount: 10,
      photoUrl:
        'https://media.tryfi.com/pet/avatar/7e5226a3f0da83ae26f118b0d5ebae35953696a3b7dba6d6d6dae6630a09905c.jpg',
    },
    {
      name: 'Echo',
      stepsThisMonth: 323114,
      age: '4yrs 4mos',
      breed: 'Treeing Walker Coonhound',
      visitCount: 10,
      photoUrl:
        'https://media.tryfi.com/pet/avatar/1d6ad6d2302e0bdca3f2a696a8bbf16fbc9168eaadc4f84b8d3e4706698f866e.jpg',
    },
    {
      name: 'Remi',
      stepsThisMonth: 309647,
      age: '2yrs 1mo',
      breed: 'Australian Shepherd',
      visitCount: 5,
      photoUrl:
        'https://media.tryfi.com/pet/avatar/68033053592a31c7f90d7a5fdd362abe1fb67965a270300f1b1983d7667ba9c0.jpg',
    },
    {
      name: 'Hef',
      stepsThisMonth: 528012,
      age: '4yrs 11mos',
      breed: 'German Shepherd Dog',
      visitCount: 4,
      photoUrl:
        'https://media.tryfi.com/pet/avatar/71e02aba0501432aa16b96b8e26bf4661a5241427dc5ee759cd36dbb5994efe0.jpg',
    },
    {
      name: 'Cooper',
      stepsThisMonth: 198133,
      age: '4yrs 8mos',
      breed: 'Golden Retriever',
      visitCount: 4,
      photoUrl:
        'https://media.tryfi.com/pet/avatar/38cbb626087c3876ef17edc6067984cac6bbcf8f43d898ac0d92129aaa438201.jpg',
    },
    {
      name: 'Charli',
      stepsThisMonth: 707932,
      age: '5yrs',
      breed: 'Labrador Retriever',
      visitCount: 2,
      photoUrl:
        'https://media.tryfi.com/pet/avatar/2160c3e43658e7a3c125371bbbf4d867d4942d028050c1c5c108bf95e7d84329.jpg',
    },
    {
      name: 'Lark',
      stepsThisMonth: 351591,
      age: '2yrs 9mos',
      breed: 'Labrador Retriever',
      visitCount: 1,
      photoUrl:
        'https://media.tryfi.com/pet/avatar/ebf346d4dd7d92556d70dff0393796635b54b14e40f2c563c3e9f93e11a9ece0.jpg',
    },
    {
      name: 'Bennie',
      stepsThisMonth: 1037896,
      age: '3yrs 7mos',
      breed: 'German Shorthaired Pointer',
      visitCount: 1,
      photoUrl:
        'https://media.tryfi.com/pet/avatar/dfd04b6068e0383ba41ff76aeded53e199547f7f1b7e00b24d9ac8247a58343c.jpg',
    },
  ],
};
