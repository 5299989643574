import { createFileRoute } from '@tanstack/react-router';
import {
  ConnectedDogParkLeaderboard,
  DogParkLeaderboardError,
} from '../components/DogParkLeaderboard/DogParkLeaderboard';
import { captureException } from '@sentry/react';
import { dogParkLeaderboardOptions } from '../components/DogParkLeaderboard/DogParkLeaderboard.query';

export const Route = createFileRoute('/dogpark/leaderboard/$id')({
  component: ConnectedDogParkLeaderboard,
  onError: ({ error }) => {
    captureException(error);
  },
  errorComponent: DogParkLeaderboardError,
  loader: ({ context: { queryClient }, params }) => {
    return queryClient.ensureQueryData(dogParkLeaderboardOptions(params.id));
  },
});
